import { ConfigProvider, Image, Layout } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'; // 导入 Routes
import MyFooter from './components/footer';
import MyHeader from './components/header';
import ScrollTopButton from './components/scrollTopButton';
import MyTable from './components/table';
import Description from './Description';
import './index.css';
import SafetyPage from './SafetyPage'; // 新页面组件

const { Content } = Layout;

const META_URL = 'https://oss.x-lab.info/open_leaderboard/meta.json';

const App = () => {
  const NODE_ENV = process.env.NODE_ENV;
  if (NODE_ENV !== 'development') {
    console.log = function () {};
  }
  let [lastUpdateTime, setLastUpdateTime] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetch(META_URL)
      .then((res) => res.json())
      .then((data) => {
        let date = new Date(data.lastUpdatedAt);
        setLastUpdateTime(date);
      });
  }, []);

  let year = null, monthIndex = null;
  if (lastUpdateTime) {
    const lastDataAvailableMonth = new Date(lastUpdateTime);
    lastDataAvailableMonth.setDate(0);
    year = lastDataAvailableMonth.getFullYear();
    monthIndex = lastDataAvailableMonth.getMonth();
  }

  return (
    <Router>
      <ConfigProvider>
        <Layout
          className="layout"
          style={{ backgroundColor: 'rgba(0,0,0,0)', minWidth: '320px' }}
        >
          <Image
            preview={false}
            style={{ zIndex: -1, position: 'absolute', top: 0 }}
            width={'100%'}
            src="/pics/Header BG.png"
          />
          <ScrollTopButton />
          <MyHeader />
          <Routes>  {/* 使用 Routes 替代 Switch */}
            <Route path="/safety" element={<SafetyPage />} />  {/* 正确的传递 JSX 元素 */}
            <Route path="/" element={<>
              <Content className="container">
                <Description lastUpdateTime={lastUpdateTime} />
              </Content>
              <MyTable t={t} year={year} month={monthIndex} />
              <MyFooter />
            </>} />
          </Routes>
          <Image
            style={{
              zIndex: -2,
              position: 'absolute',
              height: '2000px',
              bottom: '0px',
            }}
            preview={false}
            src="/pics/Bubble BG.png"
          />
        </Layout>
      </ConfigProvider>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
